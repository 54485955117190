<template>
  <div id="herder">
    <el-button
      v-throttle
      @click="quitBtn"
      size="medium"
      icon="el-icon-back"
      class="returnBtn"
      round
      >返回</el-button
    >
    <el-card shadow="always">
      <div id="county-content">
        <span>区县</span>
        <el-select
          v-model="queryParam.areaId"
          placeholder="请选择区县"
          @change="chooseExamCounty"
        >
          <el-option
            v-for="(examCounty, index) in examCountyList"
            :key="index"
            :label="examCounty.sysOrgAreName"
            :value="examCounty.sysOrgAreId"
          ></el-option>
        </el-select>
      </div>
      <div id="place-content">
        <span>考点</span>
        <el-select
          v-model="queryParam.examPlaceId"
          placeholder="请选择考点"
          @change="chooseExamPlace"
        >
          <el-option
            v-for="(examPlace, index) in examPlaceList"
            :key="index"
            :label="examPlace.sysOrgExamPlaceName"
            :value="examPlace.sysOrgExamPlaceId"
          ></el-option>
        </el-select>
      </div>
      <div class="box-content">
        <span>选择考试</span>
        <el-select
          v-model="queryParam.examId"
          placeholder="请选择考试"
          @change="chooseExamInfo"
        >
          <el-option
            v-for="(examInfo, index) in examInfoList"
            :key="index"
            :label="examInfo.examName"
            :value="examInfo.examId"
          ></el-option>
        </el-select>
      </div>
      <div class="time-content">
        <span>考试时间</span>
        <span v-if="examInfo.examStartTime != 0">{{
          formatDateTime(
            new Date(examInfo.examStartTime),
            'yyyy-MM-dd hh:mm:ss'
          )
        }}</span>
        <span v-if="examInfo.examStartTime == 0">请选择考试</span>
      </div>
      <div id="report_btn">
        <el-button
          v-throttle
          type="primary"
          size="medium"
          icon="el-icon-edit-outline"
          @click="draw"
          :disabled="
            !queryParam.areaId || !queryParam.examPlaceId || !queryParam.examId
          "
          >进入抽签</el-button
        >
      </div>
      <div id="footer-content">
        <div id="footer-content-img"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  getDrawingOrgExamInfoApi,
  getExamInfoListApi,
} from '@/api/draw/draw.js'

import { quit } from '@/api/auth.js'
import { removeToken, removeName, getLoginRoad } from '@/utils/token.js'
import { removeStore } from '@/utils/token.js'
export default {
  components: {},
  data() {
    return {
      queryParam: {
        areaId: '',
        examPlaceId: '',
        examId: '',
      },
      examCountyList: [],
      examPlaceList: [],
      examInfoList: [],
      examInfo: {
        arrangeModel: 0,
        examCode: '',
        examId: 0,
        examName: '',
        examStartTime: 0,
        randomTimingType: 0,
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getDrawingOrgExamInfo()
    },
    //获取区县
    getDrawingOrgExamInfo() {
      getDrawingOrgExamInfoApi().then((res) => {
        if (res.success) {
          this.examCountyList = []
          this.examCountyList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //选择区域
    chooseExamCounty() {
      this.queryParam.examPlaceId = ''
      this.examPlaceList = []
      this.queryParam.examId = ''
      this.examInfoList = []
      this.examInfo.examStartTime = 0
      this.examCountyList.forEach((item) => {
        if (this.queryParam.areaId == item.sysOrgAreId) {
          //渲染考点信息
          this.examPlaceList = []
          this.examPlaceList = item.sysOrgExamPlaceList
        }
      })
    },
    //选择考点
    chooseExamPlace() {
      this.queryParam.examId = ''
      this.examInfoList = []
      this.examInfo.examStartTime = 0
      this.getExamInfoList()
    },
    getExamInfoList() {
      getExamInfoListApi(this.queryParam.examPlaceId).then((res) => {
        //渲染考试信息
        this.examInfoList = []
        this.examInfoList = res.data
      })
    },
    //选择考试
    chooseExamInfo() {
      this.examInfo.examStartTime = 0
      this.examInfoList.forEach((item) => {
        if (this.queryParam.examId == item.examId) {
          this.examInfo = {}
          this.examInfo = item
        }
      })
    },
    draw() {
      this.$router.push({
        path: '/draw/draw',
        query: {
          sysOrgExamPlaceId: this.queryParam.examPlaceId,
          examId: this.queryParam.examId,
          examStartTime: this.examInfo.examStartTime,
          arrangeModel: this.examInfo.arrangeModel,
          randomTimingType: this.examInfo.randomTimingType,
        },
      })
    },
    /* 时间格式化 */
    formatDateTime(date, fmt) {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
      }
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          )
        }
      }
      return fmt
    },
    padLeftZero(str) {
      return ('00' + str).substr(str.length)
    },
    // 退出登录
    quitBtn() {
      quit().then((res) => {
        if (res.success) {
          removeStore('LOCAL_OSS')
          removeStore('LOCAL_MEDIA')
          removeToken()
          removeName()
          setTimeout(() => {
            // if (!getLoginRoad()) {
            // removeLoginRoad()
            // this.$router.replace('/selectLogin')
            this.$router.replace('/drawLotsLogin')
            // } else {
            // removeLoginRoad()
            // this.$router.replace('/cityPlatformLogin')
            // }
          }, 500)
        }
      })
    },
  },
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
}
</script>
<style lang="scss" scoped>
#herder {
  padding: 0 40px;
  margin-top: 60px;
  font-family: 'Source Han Sans CN-Medium, Source Han Sans CN';
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-card__body {
  margin-top: 40px;
}
::v-deep .el-select {
  width: 429px;
}

#county-content,
#place-content,
.box-content,
.time-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

#county-content > span:first-child,
#place-content > span:first-child,
.box-content > span:first-child,
.time-content > span:first-child {
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
  display: inline-block;
  width: 72px;
  text-align: right;
}

.time-content > span:last-child {
  display: inline-block;
  width: 429px;
  text-align: left;
  font-weight: bold;
}

#report_btn {
  margin-top: 20px;
  text-align: center;
}

#footer-content {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#footer-content-img {
  background: url('../rollCall/image/report.png') no-repeat;
  width: 1107px;
  height: 408px;
}
.returnBtn {
  position: fixed;
  top: 20px;
  left: 20px;
}
</style>
